import baseTheme from '@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui';

const theme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    modes: {
      dark: {
        ...baseTheme.colors.modes.dark,
      },
      light: {
        ...baseTheme.colors.modes.light,
        background: "#f2f2e3"
      }
    }
  }
}

export default theme
